import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

function initSentry() {
  // https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    dsn: 'https://69d8395691e44d9db94a9ad12d9d9f69@sentry-qa.frontpage-aws-nonprod.aws.oath.cloud:9000/4',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    enabled: process.env.REACT_APP_SENTRY_ENABLED === "true",

    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export { initSentry };
