import React from 'react';
import { createRoot as ReactRender } from 'react-dom/client';
import { HotkeysProvider } from '@blueprintjs/core';
import CelestialApp from 'celestial/App';
import { initSentry } from 'celestial/sentry';

const ThemeCelestial = React.lazy(() => import('themes/ThemeCelestial'));

const ThemeSelector = ({ children }) => (
  <>
    <React.Suspense fallback={<></>}>
      <ThemeCelestial />
    </React.Suspense>
    {children}
  </>
);

initSentry();

const container = document.getElementById('root');
const root = ReactRender(container);
const render = (App) =>
  root.render(
    <ThemeSelector>
      <HotkeysProvider>
        <App />
      </HotkeysProvider>
    </ThemeSelector>
  );

render(CelestialApp);

if (module.hot) {
  module.hot.accept('celestial/App', () => {
    const NextApp = require('celestial/App').default;
    render(NextApp);
  });
}
