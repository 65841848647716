import { InMemoryCache } from '@apollo/client/core';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import possibleTypes from 'data/graphql/aas/possibleTypes.json';

// to learn how to configure this -> https://www.apollographql.com/docs/react/caching/cache-configuration/
const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    QueryUsers: {
      keyFields: false,
      merge: true,
    },
    QueryResources: {
      keyFields: false,
      merge: true,
    },
  },
});

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  debug: true,
  trigger: 'write',
  key: 'aas-cache-persist',
});

export default cache;
