import { useEffect } from 'react';

// this should go at the root of the app, so that it happens once
const useRestoreCache = persistor => {
  useEffect(() => {
    const restore = async () => {
      await persistor.restore();
    };
    restore();
  }, [persistor]);
};

export default useRestoreCache;
