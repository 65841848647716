import React from 'react';
import cx from 'classnames';
import styles from './Spinner.module.scss';

const { spinner, bounce1, bounce2, bounce3, centered, extraLeftMargin } = styles;

const Spinner = ({ customSpinnerStyle = '', center = false, style = {}, noExtraLeftMargin = false }) => (
  <div
    className={cx(spinner, customSpinnerStyle, {
      [centered]: center,
      [extraLeftMargin]: extraLeftMargin && !noExtraLeftMargin,
    })}
    style={style}
    data-testid="spinner"
  >
    <div className={bounce1} data-testid="bounce1" />
    <div className={bounce2} data-testid="bounce2" />
    <div className={bounce3} data-testid="bounce3" />
  </div>
);

export default Spinner;
